import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IReturnLineItem } from '../../../services/settings.service';
import { ReturnReasonSelector } from '../ReturnReasonSelector';
import { useTranslation } from 'react-i18next';
import { QuantitySelector } from './QuantitySelector';
import { ILineItem } from '../../../services/return.service';

interface Props {
  lineItem: ILineItem;
  returnLineItem: IReturnLineItem | undefined;
  onClear: () => void;
  onClose: () => void;
  changeSelectedReturnItem: (returnLineItem: IReturnLineItem) => void;
}

export const LineItemModal: React.FC<Props> = ({
  lineItem,
  returnLineItem,
  onClear,
  onClose,
  changeSelectedReturnItem,
}) => {
  const { t } = useTranslation();
  const [returnItemUpdate, setReturnItemUpdate] = useState<IReturnLineItem>(
    returnLineItem ?? {
      lineItemId: lineItem.id,
      returnReasonId: 0,
      quantity: 1,
    },
  );

  const handleContinue = (): void => {
    changeSelectedReturnItem(returnItemUpdate);
    onClose();
  };

  const handleQuantityChange = (quantity: number): void => {
    setReturnItemUpdate((prev) => {
      return { ...prev, quantity };
    });
  };

  const setReturnReason = (returnReasonId: number): void => {
    setReturnItemUpdate((prev) => {
      return { ...prev, returnReasonId };
    });
  };

  const handleClearClick = (): void => {
    onClear();
    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      disableScrollLock={true}
      PaperProps={{
        style: {
          maxWidth: 400,
          margin: 0,
        },
      }}
    >
      <DialogTitle>
        {t('return article')}
        <Typography sx={{ mt: 2, overflowWrap: 'break-word', textAlign: 'start' }}>
          {lineItem.name} - {lineItem.externalId}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <QuantitySelector
              quantity={returnItemUpdate.quantity}
              maximum={lineItem.quantity}
              setReturnQuantity={handleQuantityChange}
            />
            <Button
              disabled={!returnLineItem}
              onClick={handleClearClick}
              color="info"
              sx={{ paddingTop: 0 }}
            >
              {t('clear')}
            </Button>
          </Stack>
          <ReturnReasonSelector
            reasonId={returnItemUpdate.returnReasonId || null}
            setReturnReason={setReturnReason}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: 'solid', borderColor: 'secondary.main', p: 2, justifyContent: 'space-between' }}>
        <Button
          onClick={onClose}
          variant="text"
          size="large"
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleContinue}
          variant="contained"
          size="large"
          disabled={!returnItemUpdate.quantity || !returnItemUpdate.returnReasonId}
        >
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Typography, Stack, Box, Button } from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ILineItem,
  createReturnShipment,
  getShipmentLabelUrlForDownload,
  useOrder,
} from '../../../services/return.service';
import { IReturnLineItem } from '../../../services/settings.service';
import { FinalReturnConfirmationModal } from './FinalReturnConfirmationModal';
import { LineItemBox } from './LineItemBox';
import { LineItemModal } from './LineItemModal';
import { PageInfo } from '../PageInfo';
import { addErrorToast, addSuccessToast } from '../../../services/notification.service';
import { settingsAtom, useUrlParams } from '../../../shared/AtomManager';

interface Props {
  lineItems: ILineItem[];
  eventAfterConfirmation: () => void;
}

interface ILineItemSelection {
  lineItems: IReturnLineItem[];
  orderId: number | null;
}

// Would be nicer with Map but jotai (atom) can't store Maps in the local storage
export const returnItemsAtom = atomWithStorage<ILineItemSelection>('returnLineItems', { lineItems: [], orderId: null });

export const ChooseLineItems: React.FC<Props> = ({ lineItems, eventAfterConfirmation }) => {
  const { t } = useTranslation();
  const [urlParams] = useUrlParams();
  const order = useOrder(urlParams.processId, urlParams.processIdentifier);
  const [settings] = useAtom(settingsAtom);

  const [selectedLineItem, setSelectedLineItem] = useState<null | ILineItem>(null);
  const [openFinalConfirmationModal, setOpenFinalConfirmationModal] = useState(false);
  const [returnLineItems, setReturnLineItems] = useAtom(returnItemsAtom);

  // When order changes, check if return line items state is still valid
  useEffect(() => {
    if (order.data?.orderId && returnLineItems.orderId !== order.data.orderId) {
      setReturnLineItems({ orderId: order.data.orderId, lineItems: [] });
    }
  }, [order.data, returnLineItems, setReturnLineItems]);

  const changeReturnLineItem = (lineItem: ILineItem, props: IReturnLineItem): void => {
    setReturnLineItems((prev) => {
      const prevLineItems = [...prev.lineItems];

      const currentItemIndex = prevLineItems.findIndex((item) => item.lineItemId === lineItem.id);
      if (currentItemIndex === -1) {
        prevLineItems.push(props);
      }
      prevLineItems[currentItemIndex] = props;
      return { ...prev, lineItems: prevLineItems };
    });
  };

  const removeFromReturnItemsById = (id: number): void => {
    setReturnLineItems((prev) => {
      const itemIndex = prev.lineItems.findIndex((item) => item.lineItemId === id);
      if (itemIndex !== -1) {
        prev.lineItems.splice(itemIndex, 1);
      }
      return prev;
    });
  };

  const resetReturnLineItems = (): void => {
    setReturnLineItems({ orderId: order.data?.orderId ?? null, lineItems: [] });
  };

  const changeSelectedReturnLineItem = (returnItem: IReturnLineItem): void => {
    if (selectedLineItem) {
      changeReturnLineItem(selectedLineItem, returnItem);
    }
  };

  const confirmReturn = async (): Promise<void> => {
    if (urlParams.processId && urlParams.processIdentifier) {
      try {
        await createReturnShipment(
          { lineItems: returnLineItems.lineItems },
          urlParams.processId,
          urlParams.processIdentifier,
        );
      } catch (error) {
        if (error instanceof Error) {
          addErrorToast(error);
        } else {
          console.log('Create return shipment error: ', error);
          addErrorToast(t('returnCreationFailed') ?? 'Return shipment creation failed');
        }
        return;
      }

      const updatedOrder = await order.mutate();
      resetReturnLineItems();
      const shipments = updatedOrder?.shipments;
      if (shipments?.length) {
        const newShipment = shipments[shipments.length - 1];

        const downloadUrl = getShipmentLabelUrlForDownload(urlParams, newShipment);

        // this could be blocked by an ad blocker, but we redirect the user to
        // the overview page afterwards. So this should be fine.
        window.open(downloadUrl, '_blank', 'noopener,noreferrer');
      }

      eventAfterConfirmation();
      addSuccessToast(t('Return shipment created'));
    }
  };

  const getReturnLineItemById = (id: number): IReturnLineItem | undefined => {
    return returnLineItems.lineItems.find((line) => line.lineItemId === id);
  };

  const selectedTotalQuantity = returnLineItems.lineItems.reduce((quantity, item) => quantity + item.quantity, 0);

  if (lineItems.length === 0) {
    return (
      <PageInfo
        title={t('LINE_ITEMS_TAB.noLineItems')}
        subtitle={t('LINE_ITEMS_TAB.noLineItemsSubtitle')}
      ></PageInfo>
    );
  }

  return (
    <Fragment>
      {!settings?.forceEmptyReturnShipment && (
        <Typography
          paddingTop={2}
          paddingBottom={4}
        >
          {t('chooseLineItemInfo')}
        </Typography>
      )}
      <Stack
        spacing={3}
        paddingBottom={10}
      >
        {settings &&
          !settings.forceEmptyReturnShipment &&
          lineItems.map((lineItem) => {
            return (
              <LineItemBox
                returnedQuantity={getReturnLineItemById(lineItem.id)?.quantity}
                key={lineItem.id}
                lineItem={lineItem}
                onClick={(): void => setSelectedLineItem(lineItem)}
              ></LineItemBox>
            );
          })}
      </Stack>
      {!!selectedLineItem && (
        <LineItemModal
          lineItem={selectedLineItem}
          returnLineItem={getReturnLineItemById(selectedLineItem.id)}
          changeSelectedReturnItem={changeSelectedReturnLineItem}
          onClose={(): void => setSelectedLineItem(null)}
          onClear={(): void => {
            removeFromReturnItemsById(selectedLineItem.id);
          }}
        />
      )}
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 1,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginX="auto"
          maxWidth="md"
          sx={{
            height: 80,
            bgcolor: 'primary.contrastText',
            borderTop: 'solid',
            borderColor: 'secondary.main',
            paddingX: { xs: 3, md: 5 },
          }}
        >
          {settings && !settings.forceEmptyReturnShipment && (
            <Button
              disabled={selectedTotalQuantity === 0}
              variant="text"
              onClick={resetReturnLineItems}
              size="large"
            >
              {t('clear all')}
            </Button>
          )}

          <Button
            onClick={(): void => setOpenFinalConfirmationModal(true)}
            variant="contained"
            disabled={!settings?.forceEmptyReturnShipment && selectedTotalQuantity === 0}
            size="large"
          >
            {settings?.forceEmptyReturnShipment
              ? t('Create empty return shipment')
              : t('chosen', { amount: returnLineItems.lineItems.length })}
          </Button>
        </Stack>
      </Box>
      <FinalReturnConfirmationModal
        isOpen={openFinalConfirmationModal}
        handleClose={(): void => setOpenFinalConfirmationModal(false)}
        handleConfirm={(): void => {
          confirmReturn();
          setOpenFinalConfirmationModal(false);
        }}
      ></FinalReturnConfirmationModal>
    </Fragment>
  );
};

import { createTheme } from '@mui/material';

const colors = {
  black: '#000000',
  white: '#ffffff',
  linkBlue: '#42A5F5',
  G600: '#ECECEC',
  G400: '#F2f2f2',
  G200: '#f9f9f9',
  GBorder: '#DBDBDB',
  B300: '#717171',
  B200: '#8e8e8e',
};

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '0.75rem',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: 'secondary',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 700,
          textAlign: 'center',
          background: colors.G200,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: '1rem',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Inter', 'sans-serif'",
    h3: {
      fontSize: '1.25rem',
      lineHeight: 1.6,
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: colors.black,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.G400,
      contrastText: colors.black,
    },
    info: {
      main: colors.linkBlue,
      contrastText: colors.black,
    },
    grey: {
      900: colors.B200,
      800: colors.B300,
      700: colors.GBorder,
      600: colors.G600,
      400: colors.G400,
      200: colors.G200,
    },
  },
});

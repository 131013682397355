import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, Stack } from '@mui/material';
import { ILoginFormFields } from './LoginForm';
import { LanguageSelector } from '../../shared/language-selector/LanguageSelector';
import { ILoginWithMacParams, login, loginWithMac } from '../../services/authentication.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import defaultBackground from './../../assets/images/default_background.jpg';
import { ShopLogo } from '../../shared/shop-logo/ShopLogo';
import { addErrorToast } from '../../services/notification.service';
import { ShopDescription } from './ShopDescription';
import { settingsAtom, useUrlParams } from '../../shared/AtomManager';
import axios from 'axios';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [settings] = useAtom(settingsAtom);
  const [urlParams] = useUrlParams();
  const { processIdentifier, processId } = urlParams;

  const [searchParams] = useSearchParams();
  const [loginWithMacTriggered, setLoginWithMacTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (!loginWithMacTriggered) {
      const macLogin = async (params: ILoginWithMacParams): Promise<void> => {
        try {
          setLoginWithMacTriggered(true);
          await loginWithMac(params);
          navigate('./../return');
        } catch (error) {
          if (error instanceof Error) {
            addErrorToast(error);
          } else {
            console.log('Error during automatic login: ', error);
            addErrorToast(t('macLoginFailed') ?? 'Automatic login failed');
          }
        }
      };

      const loginMac = searchParams.get('mac');
      const orderId = searchParams.get('orderId');
      const parsedOrderId: number | undefined = orderId ? parseInt(orderId, 10) : undefined;

      if (
        processId &&
        processIdentifier &&
        loginMac &&
        parsedOrderId &&
        isFinite(parsedOrderId) &&
        !loginWithMacTriggered
      ) {
        macLogin({
          processId,
          processIdentifier,
          loginMac,
          orderId: parsedOrderId,
        });
      }
    }
  }, [searchParams, loginWithMacTriggered, t, navigate, processIdentifier, processId]);

  const onSubmit = async (loginFormFields: ILoginFormFields): Promise<void> => {
    if (processId && processIdentifier) {
      try {
        await login(processId, processIdentifier, { ...loginFormFields, processId });
        navigate(`/${processIdentifier}/${processId}/return`);
      } catch (error) {
        if (error instanceof Error) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            addErrorToast(t('orderNotFound') ?? 'Order could not be found');
            return;
          }
          addErrorToast(error);
        } else {
          console.log('Login error: ', error);
          addErrorToast(t('loginFailed') ?? 'Login failed');
        }
      }
    }
  };

  return (
    <Grid
      container
      height="100vh"
    >
      <Grid
        item
        md={4}
        lg={6}
        height="100%"
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Box
          component="img"
          sx={{
            display: 'block',
            height: '100%',
            objectFit: 'cover',
            width: '100%',
          }}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
            e.currentTarget.src = defaultBackground;
          }}
          src={settings ? settings.backgroundUrl || defaultBackground : undefined}
        />
      </Grid>
      {settings && (
        <Grid
          item
          xs={12}
          md={8}
          lg={6}
        >
          <Stack alignItems="center">
            <Stack
              spacing={3}
              sx={{
                minWidth: { md: '35rem' },
                width: { xs: '100vw', md: '30vw' },
                paddingX: { xs: 3, md: 5 },
                marginTop: { xs: 5, md: 15 },
                marginBottom: 3,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: { xs: 2, md: 5 } }}
              >
                <ShopLogo settings={settings}></ShopLogo>
                <LanguageSelector languages={settings.languages}></LanguageSelector>
              </Stack>

              <ShopDescription
                description={t('description')}
                onSubmit={onSubmit}
              ></ShopDescription>

              {(settings.customerPrivacyPolicy || settings.customerImprint) && (
                <Stack
                  sx={{ marginTop: 7 }}
                  direction="row"
                  spacing={3}
                >
                  {settings.customerPrivacyPolicy && (
                    <Link
                      href={settings.customerPrivacyPolicy}
                      color="primary"
                    >
                      {t('Privacy Policy')}
                    </Link>
                  )}
                  {settings.customerImprint && (
                    <Link
                      href={settings.customerImprint}
                      color="primary"
                    >
                      {t('Imprint')}
                    </Link>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

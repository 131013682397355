import i18n from '../../translations/i18n';

export const isoDateToLocaleDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  return date.toLocaleDateString(i18n.language);
};

export const getLoginUrl = (processIdentifier: string, processId: number): string => {
  return `/${processIdentifier}/${processId}/login`;
};

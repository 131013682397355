import useSWR from 'swr';
import { IParsedUrlParams } from '../shared/AtomManager';

import client, { backendUrl } from '../shared/client';
import { IReturnShipment } from './settings.service';

export interface ILineItem {
  id: number;
  externalId: string;
  name: string;
  quantity: number;
  returnReasonId: number | null;
  returnQuantity: number | null;
}

export interface IShipment {
  shipmentId: number;
  returnTrackingId: string | null;
  lineItems: ILineItem[];
}

export interface IReturnOrder {
  orderId: number;
  name: string | null;
  customerFacingId: string | null;
  grossPrice: number | undefined;
  currency: string | undefined;
  date: string | null;
  lineItems: ILineItem[];
  shipments: IShipment[];
  email: string;
  processId: number;
}

export const createReturnShipment = async (
  body: IReturnShipment,
  processId: number,
  processIdentifier: string,
): Promise<IReturnOrder> => {
  const url = new URL(`${backendUrl}/public/return/${processId}/${processIdentifier}/shipment`);

  const response = await client.post<IReturnOrder>(url.href, body);
  return response.data;
};

export const getShipmentLabelUrlForDownload = (urlParams: IParsedUrlParams, shipment: IShipment): string => {
  const url = new URL(
    `${backendUrl}/public/return/${urlParams.processId}/${urlParams.processIdentifier}/shipment/${shipment.shipmentId}-label.pdf`,
  );

  return url.href;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOrder = (processId: number | null, processIdentifier: string | null) => {
  let url = undefined;

  if (processId && processIdentifier) {
    url = new URL(`${backendUrl}/public/return/${processId}/${processIdentifier}/order`).href;
  }

  const { data, error, isLoading, mutate } = useSWR<IReturnOrder>(url);
  return {
    data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

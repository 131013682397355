import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReturnShipment } from './ReturnShipment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAtom } from 'jotai';
import { PageInfo } from '../PageInfo';
import { IReturnOrder } from '../../../services/return.service';
import { settingsAtom } from '../../../shared/AtomManager';

interface ReturnTabProps {
  order: IReturnOrder | null;
}

export const ReturnTab: React.FC<ReturnTabProps> = ({ order }) => {
  const { t } = useTranslation();
  const [settings] = useAtom(settingsAtom);

  const howToShipSteps = [1, 2, 3, 4, 5, 6];

  return (
    <>
      {!order?.shipments?.length && (
        <PageInfo
          title={t('RETURNS_TAB.noReturns')}
          subtitle={t('RETURNS_TAB.noReturnsSubtitle')}
        ></PageInfo>
      )}
      {order?.shipments
        ?.sort((a, b) => b.shipmentId - a.shipmentId)
        .map((shipment) => {
          return (
            <ReturnShipment
              shipment={shipment}
              key={shipment.shipmentId}
            ></ReturnShipment>
          );
        })}

      <Accordion
        sx={{
          mt: 4,
          boxShadow: 'none',
          border: 1,
          borderColor: 'secondary.main',
          borderRadius: 1,
          ':before': { display: 'none' },
          maxWidth: '30rem',
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{t('RETURNS_TAB.howToShip.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {howToShipSteps.map((step) => (
            <Typography
              key={step}
              variant="body2"
              mb={1}
            >
              {t('RETURNS_TAB.howToShip.step' + step)}
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>

      {settings?.contactEmail && (
        <Accordion
          sx={{
            mt: 2,
            boxShadow: 'none',
            border: 1,
            borderColor: 'secondary.main',
            borderRadius: 1,
            ':before': { display: 'none' },
            maxWidth: '30rem',
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">{t('contact us')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {t('contact us at')}
              <a href={`mailto:${settings.contactEmail}`}>{settings.contactEmail}</a>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

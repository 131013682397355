import { Box, Tab } from '@mui/material';
import React from 'react';
import { useOrder } from '../../services/return.service';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ChooseLineItems } from './line-items-tab/ChooseLineItems';
import { ReturnTab } from './return-shipments-tab/ReturnTab';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { TabLabel } from './TabLabel';
import { settingsAtom, useUrlParams } from '../../shared/AtomManager';

enum ReturnPageTab {
  CHOOSE_LINE_ITEMS = '1',
  RETURN_SHIPMENTS = '2',
}

interface Props {}

export const ReturnPageTabs: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [urlParams] = useUrlParams();
  const { data: order } = useOrder(urlParams.processId, urlParams.processIdentifier);
  const [settings] = useAtom(settingsAtom);

  const [selectedTab, setSelectedTab] = React.useState(ReturnPageTab.CHOOSE_LINE_ITEMS);

  const handleChange = (event: React.SyntheticEvent, newSelectedTab: ReturnPageTab): void => {
    setSelectedTab(newSelectedTab);
  };

  const totalQuantity = order?.lineItems.reduce((prev, curr) => prev + curr.quantity, 0) ?? 0;

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList
            variant="fullWidth"
            onChange={handleChange}
          >
            <Tab
              label={
                <TabLabel
                  label={t('available items')}
                  quantity={settings?.forceEmptyReturnShipment ? 0 : totalQuantity}
                  selected={selectedTab === ReturnPageTab.CHOOSE_LINE_ITEMS}
                ></TabLabel>
              }
              value={ReturnPageTab.CHOOSE_LINE_ITEMS}
            />
            <Tab
              label={
                <TabLabel
                  label={t('return')}
                  quantity={order?.shipments.length ?? 0}
                  selected={selectedTab === ReturnPageTab.RETURN_SHIPMENTS}
                ></TabLabel>
              }
              value={ReturnPageTab.RETURN_SHIPMENTS}
            />
          </TabList>
        </Box>
        <TabPanel
          value={ReturnPageTab.CHOOSE_LINE_ITEMS}
          sx={{ paddingX: 0, paddingTop: 0 }}
        >
          <ChooseLineItems
            lineItems={order?.lineItems || []}
            eventAfterConfirmation={(): void => setSelectedTab(ReturnPageTab.RETURN_SHIPMENTS)}
          ></ChooseLineItems>
        </TabPanel>
        <TabPanel
          value={ReturnPageTab.RETURN_SHIPMENTS}
          sx={{ paddingX: 0 }}
        >
          <ReturnTab order={order ?? null}></ReturnTab>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { useOrder } from '../../services/return.service';

import { SelectAllItemsModal } from './SelectAllItemsModal';
import { ReturnPageHeader } from './ReturnPageHeader';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { IReturnLineItem } from '../../services/settings.service';
import { ReturnPageTabs } from './ReturnPageTabs';
import { isoDateToLocaleDate } from '../../shared/helper/helper';
import { returnItemsAtom } from './line-items-tab/ChooseLineItems';
import { useUrlParams } from '../../shared/AtomManager';

export const ReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const [urlParams] = useUrlParams();
  const [isReturnAllModalOpen, setIsReturnAllModalOpen] = React.useState(false);
  const [, setReturnLineItems] = useAtom(returnItemsAtom);

  const { data: order } = useOrder(urlParams.processId, urlParams.processIdentifier);

  const selectAllLineItems = async (lineItems: IReturnLineItem[]): Promise<void> => {
    setReturnLineItems({ orderId: order?.orderId ?? null, lineItems });
    setIsReturnAllModalOpen(false);
  };

  const paddingX = { xs: 3, md: 5 };

  return (
    <Box
      width="100vw"
      maxWidth="100%"
      sx={{ backgroundColor: 'secondary.main' }}
    >
      <Container
        maxWidth="md"
        sx={{
          minHeight: '100vh',
          backgroundColor: 'primary.contrastText',
          paddingY: '0!important',
          paddingX,
        }}
      >
        <ReturnPageHeader />
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          sx={{ marginY: { xs: 2, sm: 3, md: 4 } }}
          alignItems="flex-start"
        >
          <Typography variant="h3">{order?.name}</Typography>
          <Typography variant="h4">{order?.customerFacingId}</Typography>
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
          >
            {order?.date && <Typography variant="body2">{isoDateToLocaleDate(order.date)}</Typography>}
            {order?.currency && order.grossPrice && (
              <Typography variant="body2">{`${order?.currency} ${order?.grossPrice}`}</Typography>
            )}
          </Stack>
          {!!order?.lineItems.length && (
            <Button
              onClick={(): void => setIsReturnAllModalOpen(true)}
              variant="outlined"
              size="large"
            >
              {t('return all items')}
            </Button>
          )}
        </Stack>
        <ReturnPageTabs />
        {isReturnAllModalOpen && !!order?.lineItems.length && (
          <SelectAllItemsModal
            handleClose={(): void => {
              setIsReturnAllModalOpen(false);
            }}
            lineItems={order.lineItems}
            handleConfirm={selectAllLineItems}
          />
        )}
      </Container>
    </Box>
  );
};

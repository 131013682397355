import React, { ReactElement, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface ILoginFormFields {
  email: string;
  customerFacingId: string;
}

interface Props {
  onSubmit: (values: ILoginFormFields) => Promise<void>;
}

export const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<ILoginFormFields>({ defaultValues: { email: '', customerFacingId: '' } });

  const setLoadingAndSubmit = async (values: ILoginFormFields): Promise<void> => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  };

  return (
    <Stack
      component="form"
      height="22rem"
      justifyContent="space-between"
      onSubmit={handleSubmit(setLoadingAndSubmit)}
    >
      <Box>
        <Typography variant="h3">{t('return')}</Typography>
        <Typography variant="subtitle1">{t('returnLoginInfo')}</Typography>
      </Box>

      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, value } }): ReactElement => (
          <TextField
            fullWidth
            required
            name="email"
            type="email"
            label={t('Email address')}
            value={value}
            autoComplete="off"
            onChange={onChange}
          />
        )}
      ></Controller>

      <Controller
        name="customerFacingId"
        control={control}
        render={({ field: { onChange, value } }): ReactElement => (
          <TextField
            fullWidth
            required
            name="customerFacingId"
            label={t('Order ID')}
            value={value}
            autoComplete="off"
            onChange={onChange}
          />
        )}
      ></Controller>

      <LoadingButton
        variant="contained"
        type="submit"
        fullWidth
        loading={loading}
        loadingPosition="end"
      >
        {t('start return')}
      </LoadingButton>
    </Stack>
  );
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import { TRANSLATIONS_EN } from './en/translations';
import TRANSLATIONS_DE from './de.json';
import TRANSLATIONS_EN from './en.json';

const LANG_RESOURCES = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  de: {
    translation: TRANSLATIONS_DE,
  },
};

export const supportedLangs = Object.keys(LANG_RESOURCES);

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: LANG_RESOURCES,
  supportedLngs: supportedLangs,
  fallbackLng: 'en',
});

export default i18n;

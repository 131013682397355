import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoginForm, ILoginFormFields } from './LoginForm';

interface Props {
  description: string | null;
  onSubmit: (values: ILoginFormFields) => Promise<void>;
}

/**
 * Either display description or login form
 */
export const ShopDescription: React.FC<Props> = ({ description, onSubmit }) => {
  const { t } = useTranslation();

  const [hideDescription, setHideDescription] = useState<boolean>(false);

  return (
    <>
      {(!description || hideDescription) && <LoginForm onSubmit={onSubmit}></LoginForm>}
      {description && !hideDescription && (
        <Stack>
          <Typography
            variant="h3"
            mb={4}
          >
            {t('Shop description')}
          </Typography>
          <Typography
            sx={{ mb: 2, flexGrow: 1 }}
            variant="subtitle1"
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            sx={{ marginTop: 4 }}
            onClick={(): void => setHideDescription(true)}
          >
            {t('Proceed to login')}
          </Button>
        </Stack>
      )}
    </>
  );
};

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

interface Props {
  title: string;
  subtitle: string;
}

export const PageInfo: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <Stack
      alignItems="center"
      marginTop={7}
      marginBottom={10}
    >
      <Typography variant="h4">{title}</Typography>
      <Typography
        variant="subtitle1"
        textAlign="center"
        mt={1}
      >
        {subtitle}
      </Typography>
    </Stack>
  );
};

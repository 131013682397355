import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { logout } from '../services/authentication.service';
import { addErrorToast } from '../services/notification.service';
import { backendUrl } from '../shared/client';
import { getLoginUrl } from '../shared/helper/helper';
import { useUrlParams } from '../shared/AtomManager';

interface Props {
  children: React.ReactNode;
}

export const SWRProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [urlParams] = useUrlParams();

  const fetcher = (url: string): Promise<void> => {
    return axios
      .get(url, {
        baseURL: backendUrl,
      })
      .then((res) => res.data);
  };

  return (
    <SWRConfig
      value={{
        fetcher,
        onError: async (error, key): Promise<void> => {
          if (error?.response?.status === 401) {
            if (urlParams.processId && urlParams.processIdentifier) {
              await logout(urlParams.processId, urlParams.processIdentifier);
              navigate(getLoginUrl(urlParams.processIdentifier, urlParams.processId));
            }

            addErrorToast(error);
            return;
          }

          console.warn('Error while getting data with SWR', { error, key });
          addErrorToast(error);
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { SWRProvider } from './contexts/SWRProvider';
import './translations/i18n';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Router>
      <SWRProvider>
        <App />
        <Toaster
          toastOptions={{
            style: {
              minWidth: '10vw',
              maxWidth: '80vw',
              maxHeight: '8rem',
              overflow: 'hidden',
              fontSize: '0.875rem',
              wordBreak: 'break-word',
            },
          }}
        />
      </SWRProvider>
    </Router>
  </React.StrictMode>,
);

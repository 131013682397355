import axios from 'axios';
import { ToastOptions } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

export const addErrorToast = (error: Error | string): void => {
  let message = error.toString();
  if (axios.isAxiosError(error)) {
    if (error.code === 'ERR_NETWORK') {
      message = `Network error: Server not reachable. (${error.message})`;
    } else if (error.response) {
      const url = error.response?.config?.url || '<unknown url>';
      const status = error.response.status;

      const showContext = error.response.data?.context && JSON.stringify(error.response.data?.context).length > 2;
      let customMessage = error.response.data?.message;
      if (showContext) {
        customMessage = customMessage.concat(` | ${JSON.stringify(error.response.data?.context)}`);
      }

      const defaultMessage = `The server responded with an error code ${status} for ${url}`;

      message = error.response.data?.message ? customMessage : defaultMessage;
    } else if (error.request) {
      message = 'The server does not respond';
    } else {
      message = `An error occured: ${error}`;
    }
  }

  toast.error(message || 'An error without a description occured', {
    duration: 10000,
  });
};

export const addSuccessToast = (message: string, options: ToastOptions = { duration: 5000 }): void => {
  if (!options?.duration) {
    options.duration = 5000;
  }
  toast.success(message, options);
};

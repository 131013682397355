import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import PrintIcon from '@mui/icons-material/Print';
import { useAtom } from 'jotai';
import { returnItemsAtom } from './ChooseLineItems';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const MAXIMUM_LINE_ITEMS_PER_SHIPMENT = 90;

export const FinalReturnConfirmationModal: React.FC<Props> = ({ isOpen, handleConfirm, handleClose }) => {
  const { t } = useTranslation();
  const [returnLineItems] = useAtom(returnItemsAtom);

  const hasTooManyLineItems = returnLineItems.lineItems.length > MAXIMUM_LINE_ITEMS_PER_SHIPMENT;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        disableScrollLock={true}
        PaperProps={{
          style: {
            maxWidth: 400,
            margin: 0,
          },
        }}
      >
        <DialogTitle
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <WarningIcon color="warning" />
          <Box
            ml={1}
            component="span"
          >
            {!hasTooManyLineItems ? t('final return confirmation') : t('too many articles')}
          </Box>
        </DialogTitle>

        <DialogContent>
          {!hasTooManyLineItems && <Typography paddingX={2}>{t('statusOfArticleIsFinal')}</Typography>}
          {hasTooManyLineItems && (
            <>
              <Typography
                paddingX={2}
                paddingBottom={3}
              >
                {t('too many selected articles', {
                  selectedLineItems: returnLineItems.lineItems.length,
                  maximumLineItems: MAXIMUM_LINE_ITEMS_PER_SHIPMENT,
                })}
              </Typography>
              <Typography paddingX={2}>{t('reduce amount of articles')}</Typography>
            </>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            borderTop: 'solid',
            borderColor: 'secondary.main',
            p: 2,
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleClose}
            variant="text"
            size="large"
          >
            {t('cancel')}
          </Button>
          <Button
            sx={{ px: 2 }}
            startIcon={<PrintIcon />}
            disabled={returnLineItems.lineItems.length > MAXIMUM_LINE_ITEMS_PER_SHIPMENT}
            onClick={handleConfirm}
            variant="contained"
            size="large"
          >
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import client from '../shared/client';

export interface ILoginWithMacParams {
  processId: number;
  processIdentifier: string;
  loginMac: string;
  orderId: number;
}

export const login = async (
  processId: number,
  processIdentifier: string,
  body: { customerFacingId: string; processId: number; email: string },
): Promise<void> => {
  return await client.post(`public/return/${processId}/${processIdentifier}/login`, body);
};

export const loginWithMac = async (params: ILoginWithMacParams): Promise<void> => {
  return await client.post(`public/return/${params.processId}/${params.processIdentifier}/login/mac`, {
    mac: params.loginMac,
    orderId: params.orderId,
  });
};

export const logout = async (processId: number, processIdentifier: string): Promise<void> => {
  return await client.delete(`public/return/${processId}/${processIdentifier}/logout`);
};

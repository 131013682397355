import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { ISettings } from '../../services/settings.service';

interface ShopLogoProps {
  settings: ISettings;
}

export const ShopLogo: React.FC<ShopLogoProps> = ({ settings }) => {
  const [showLogoImage, setShowLogoImage] = useState<boolean>(true);

  const imgSrc = !!settings.logoUrl ? settings.logoUrl : undefined;

  return (
    <Stack alignItems="flex-start">
      {showLogoImage && (
        <Box
          component="img"
          sx={{
            objectFit: 'contain',
            maxHeight: { xs: 60, md: 70 },
            maxWidth: { xs: 120, md: 240 },
            width: '100%', // for some svg images without size information, this is needed
          }}
          src={imgSrc}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>): void => setShowLogoImage(false)}
        />
      )}
    </Stack>
  );
};

import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from '../../../shared/CopyToClipboard';
import { IShipment, getShipmentLabelUrlForDownload } from '../../../services/return.service';
import { useUrlParams } from '../../../shared/AtomManager';

interface ReturnShipmentProps {
  shipment: IShipment;
}

export const ReturnShipment: React.FC<ReturnShipmentProps> = ({ shipment }) => {
  const [urlParams] = useUrlParams();
  const { t } = useTranslation();

  return (
    <Box sx={{ border: 2, borderColor: 'secondary.main', borderRadius: 1, marginBottom: 2 }}>
      <Box
        bgcolor="grey.200"
        p={2}
      >
        <Typography variant="h6">{t('Postal tracking code')}</Typography>
        <Typography variant="h5">
          {shipment.returnTrackingId ?? t('Not printed')}
          {shipment.returnTrackingId && <CopyToClipboard text={shipment.returnTrackingId.toString()}></CopyToClipboard>}
        </Typography>
      </Box>
      <Box>
        {shipment.lineItems.map((lineItem) => {
          return (
            <Box
              key={lineItem.id}
              sx={{
                paddingY: 2,
                marginX: 2,
                borderBottom: 1,
                borderColor: 'secondary.main',
                ':last-child': { border: 'none' },
              }}
            >
              <Typography
                sx={{ overflowWrap: 'break-word' }}
                variant="body2"
              >
                {lineItem.quantity} x {lineItem.name}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Button
        variant="contained"
        size="medium"
        sx={{ m: 2 }}
        startIcon={<PrintIcon />}
        component="a"
        href={getShipmentLabelUrlForDownload(urlParams, shipment)}
        rel="noopener,noreferrer"
      >
        {t('Print replacement label')}
      </Button>
    </Box>
  );
};

import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';

interface TabLabelProps {
  label: string;
  quantity: number | string;
  selected: boolean;
}

export const TabLabel: React.FC<TabLabelProps> = ({ label, quantity, selected }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
    >
      <Typography
        textTransform="capitalize"
        variant="h4"
      >
        {label}
      </Typography>

      {selected && (
        <Chip
          size="small"
          color="primary"
          label={quantity}
          sx={{
            ml: 1,
            border: 1,
            borderRadius: '50%',
            width: '2.5rem',
            height: '2.5rem',
            flexShrink: 0,
            display: { xs: 'none', sm: 'flex' },
          }}
        />
      )}
    </Stack>
  );
};

import { Typography, Button, Stack } from '@mui/material';
import React from 'react';

export interface IErrorPageProps {
  heading: string;
  message: string;
  buttonText?: string;
  buttonLink?: () => void;
}

const ErrorPage: React.FC<IErrorPageProps> = (props) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        height="40rem"
        marginX={3}
        maxWidth="40rem"
      >
        <Typography
          variant="h3"
          mb={2}
        >
          {props.heading}
        </Typography>
        <Typography
          variant="subtitle1"
          mb={2}
          textAlign="center"
        >
          {props.message}
        </Typography>

        {props.buttonText && props.buttonLink && (
          <Button
            variant="contained"
            color="secondary"
            onClick={props.buttonLink ?? undefined}
          >
            {props.buttonText}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default ErrorPage;

import { Box, IconButton, Stack } from '@mui/material';
import React from 'react';
import { LanguageSelector } from '../../shared/language-selector/LanguageSelector';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/authentication.service';
import { ShopLogo } from '../../shared/shop-logo/ShopLogo';
import { settingsAtom, useUrlParams } from '../../shared/AtomManager';
import { getLoginUrl } from '../../shared/helper/helper';

export const ReturnPageHeader: React.FC = () => {
  const navigate = useNavigate();
  const [settings] = useAtom(settingsAtom);
  const [urlParams] = useUrlParams();

  const handleLogout = async (): Promise<void> => {
    if (urlParams.processId && urlParams.processIdentifier) {
      await logout(urlParams.processId, urlParams.processIdentifier);
      navigate(getLoginUrl(urlParams.processIdentifier, urlParams.processId));
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        paddingY: { xs: 0.5, md: 1 },
        alignItems: 'center',
        borderBottom: 'solid',
        borderColor: 'secondary.main',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>{settings && <ShopLogo settings={settings} />}</Box>
      {settings && <LanguageSelector languages={settings.languages}></LanguageSelector>}
      <IconButton
        sx={{ marginLeft: 2, paddingRight: 0 }}
        onClick={handleLogout}
      >
        <LogoutIcon />
      </IconButton>
    </Stack>
  );
};

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { IsoLanguage, ILanguage } from '../../services/settings.service';
import i18n from '../../translations/i18n';
import { Typography } from '@mui/material';
import interfaceLanguages from '../../translations/interfaceLanguages';

interface LanguageSelectorProps {
  languages: ILanguage[];
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ languages }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const availableLanguages = getIntersectionShopAndInterfaceLanguages(languages);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const choseOption = (option: IsoLanguage) => {
    return () => {
      i18n.changeLanguage(option);
      closeMenu();
    };
  };

  const unselectedLanguages = availableLanguages.filter((lang) => lang !== i18n.language);
  return (
    <>
      {availableLanguages.length > 1 && (
        <div>
          <Button
            variant="text"
            disableElevation
            onClick={handleOpenMenu}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <Typography
              variant="h4"
              component="span"
            >
              {i18n.language}
            </Typography>
          </Button>
          <Menu
            sx={{ width: '100%' }}
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
          >
            {unselectedLanguages.map((language) => {
              return (
                <MenuItem
                  key={language}
                  sx={{ textTransform: 'uppercase' }}
                  onClick={choseOption(language)}
                  disableRipple
                >
                  <Typography variant="h4">{language}</Typography>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )}
    </>
  );
};

const getIntersectionShopAndInterfaceLanguages = (languages: ILanguage[]): IsoLanguage[] => {
  const shopLanguages = languages.map((language) => language.languageCode);
  return shopLanguages
    .filter((lang) => !!lang)
    .map((lang) => lang.toLowerCase() as IsoLanguage)
    .filter((lang) => !!interfaceLanguages.includes(lang));
};

import React, { PropsWithChildren } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { LoginPage } from './pages/login/LoginPage';
import { ReturnPage } from './pages/return/ReturnPage';
import ErrorPage from './shared/error-page/ErrorPage';
import { useSettings } from './services/settings.service';
import { AtomManager, useUrlParams } from './shared/AtomManager';

const DeprecatedUrlError: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const oldUrlFormat = /^\/?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\/(de-ch|de-gb)\/(retoure-management|retoure)$/g;

  return oldUrlFormat.test(location.pathname) ? (
    <ErrorPage
      heading="Invalid URL"
      message="It looks like you specified an url in a format which is not supported anymore.
      Please contact your shop to learn about the new url format."
    ></ErrorPage>
  ) : (
    <>{children}</>
  );
};

/**
 * If not a valid processId can be extracted from the url or the shop settings fail to load, display an error
 */
const InvalidProcessError: React.FC<PropsWithChildren> = ({ children }) => {
  const [urlParams] = useUrlParams();
  const settings = useSettings(urlParams.processId?.toString(), urlParams.processIdentifier);

  return !urlParams.processId || !urlParams.processIdentifier || (!settings.isLoading && !settings.data) ? (
    <ErrorPage
      heading="Page not found"
      message="The page you requested does not exist"
    ></ErrorPage>
  ) : (
    <>{children}</>
  );
};

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/:processIdentifier/:processId"
        element={
          <>
            <AtomManager />
            <InvalidProcessError>
              <Outlet />
            </InvalidProcessError>
          </>
        }
      >
        <Route
          path="/:processIdentifier/:processId/login"
          element={<LoginPage />}
        />

        <Route
          path="/:processIdentifier/:processId/return"
          element={<ReturnPage />}
        />
      </Route>

      <Route
        path="*"
        element={
          <DeprecatedUrlError>
            <ErrorPage
              heading="Page not found"
              message="The page you requested does not exist"
            ></ErrorPage>
          </DeprecatedUrlError>
        }
      />
    </Routes>
  );
};

export default AppRouter;

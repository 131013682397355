import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IReturnLineItem } from '../../services/settings.service';
import { ReturnReasonSelector } from './ReturnReasonSelector';
import { useTranslation } from 'react-i18next';
import { ILineItem } from '../../services/return.service';

interface Props {
  lineItems: ILineItem[];
  handleClose: () => void;
  handleConfirm: (returnItems: IReturnLineItem[]) => void;
}

export const SelectAllItemsModal: React.FC<Props> = ({ lineItems, handleConfirm, handleClose }) => {
  const { t } = useTranslation();

  const [returnReasonId, setReturnReasonId] = useState<number | null>(null);

  const handleConfirmClick = (): void => {
    if (returnReasonId !== null) {
      const returnLineItems: IReturnLineItem[] = lineItems.map((item) => {
        return {
          lineItemId: item.id,
          returnReasonId: returnReasonId,
          quantity: item.quantity,
        };
      });
      handleConfirm(returnLineItems);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      disableScrollLock={true}
      PaperProps={{
        style: {
          maxWidth: 400,
          margin: 0,
        },
      }}
    >
      <DialogTitle>{t('return all items')}</DialogTitle>

      <DialogContent>
        <Typography mb={2}>{t('chooseReturnReason')}</Typography>
        <ReturnReasonSelector
          reasonId={returnReasonId}
          setReturnReason={setReturnReasonId}
        />
      </DialogContent>

      <DialogActions sx={{ borderTop: 'solid', borderColor: 'secondary.main', p: 2, justifyContent: 'space-between' }}>
        <Button
          onClick={handleClose}
          variant="text"
          size="large"
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={returnReasonId === null}
          onClick={handleConfirmClick}
          variant="contained"
          size="large"
        >
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

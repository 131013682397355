import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { settingsAtom } from '../../shared/AtomManager';

interface Props {
  reasonId: number | null;
  setReturnReason: (returnReasonId: number) => void;
}

export const ReturnReasonSelector: React.FC<Props> = ({ reasonId, setReturnReason }) => {
  const { t } = useTranslation();
  const [settings] = useAtom(settingsAtom);

  return (
    <Stack>
      <Typography variant="h5">
        {t('Return reason')}
        <Box
          component="span"
          color="error.main"
        >
          *
        </Box>
      </Typography>

      <RadioGroup
        value={reasonId}
        onChange={(e): void => setReturnReason(parseInt(e.target.value, 10))}
      >
        {settings?.reasons &&
          settings.reasons.map((reason) => {
            const existingTranslation = t(`reason${reason.index}`)?.trim();
            return existingTranslation ? (
              <FormControlLabel
                key={reason.reasonId}
                value={reason.reasonId}
                control={<Radio />}
                label={existingTranslation}
              />
            ) : null;
          })}
      </RadioGroup>
    </Stack>
  );
};

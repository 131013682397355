import { Box, ButtonBase, Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ILineItem } from '../../../services/return.service';

interface Props {
  lineItem: ILineItem;
  returnedQuantity: number | undefined;
  onClick: () => void;
}

export const LineItemBox: React.FC<Props> = ({ lineItem, returnedQuantity, onClick }) => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      key={lineItem.id}
      onClick={onClick}
      sx={{ boxShadow: 'none' }}
    >
      <Box
        sx={{
          width: '100%',
          padding: 2,
          border: 2,
          borderRadius: 2,
          borderColor: returnedQuantity ? 'primary' : 'grey.700',
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          alignItems="flex-start"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          <Typography variant="body2">{lineItem.name}</Typography>
          <Chip
            color={returnedQuantity ? 'primary' : 'secondary'}
            label={
              <Typography variant="h6">
                {returnedQuantity
                  ? t('LINE_ITEMS_TAB.selectedQuantity', {
                      availableQuantity: lineItem.quantity,
                      selectedQuantity: returnedQuantity,
                    })
                  : t('LINE_ITEMS_TAB.availableQuantity', {
                      availableQuantity: lineItem.quantity,
                    })}
              </Typography>
            }
            sx={{ mt: 2 }}
          />
        </Stack>
      </Box>
    </ButtonBase>
  );
};

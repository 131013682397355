import { IconButton, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from 'react-i18next';
interface Props {
  quantity: number;
  maximum: number;
  setReturnQuantity: (quantity: number) => void;
}

export const QuantitySelector: React.FC<Props> = ({ quantity, maximum, setReturnQuantity }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState<number | ''>(quantity);

  const processInput = (input: string | number): void => {
    input = input.toString();
    const isNumber = /^\d+$/.test(input);
    if (isNumber) {
      const inputAsNumber = parseInt(input, 10);
      const correctedInput = inputInRange(inputAsNumber)
        ? inputAsNumber
        : Math.max(0, Math.min(inputAsNumber, maximum));

      setInput(correctedInput);
      setReturnQuantity(correctedInput);
    }
    if (input === '') {
      setInput(input);
      setReturnQuantity(0);
    }
  };

  const inputInRange = (value = input): boolean => {
    if (value === '') {
      return false;
    }
    return value <= maximum && value >= 0;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    processInput(value);
  };

  const increment = (): void => {
    processInput(input ? input + 1 : 1);
  };

  const decrement = (): void => {
    processInput(input ? input - 1 : 0);
  };

  const isIncrementable = input === '' || input < maximum;
  const isDecrementable = !!input;

  return (
    <Stack>
      <Typography
        variant="h5"
        mb={1}
      >
        {t('Quantity')}
        <Box
          component="span"
          color="error.main"
        >
          *
        </Box>
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
      >
        <IconButton
          disabled={!isDecrementable}
          onClick={decrement}
          size="large"
          sx={{ p: 0 }}
        >
          <RemoveCircleIcon
            color={isDecrementable ? 'primary' : 'disabled'}
            fontSize="inherit"
          />
        </IconButton>
        <TextField
          error={!inputInRange()}
          size="small"
          sx={{ width: 50, mx: 2 }}
          inputProps={{ inputMode: 'numeric', min: '0', pattern: '[0-9]*' }}
          value={input}
          onChange={onChange}
        />

        <IconButton
          disabled={!isIncrementable}
          onClick={increment}
          size="large"
          sx={{ p: 0 }}
        >
          <AddCircleIcon
            color={isIncrementable ? 'primary' : 'disabled'}
            fontSize="inherit"
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

import React, { useRef, useState } from 'react';
import { ButtonBase, Stack } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCirlceIcon from '@mui/icons-material/CheckCircle';

interface CopyToClipboardProps {
  text: string;
  secondsBeforeReset?: number;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text, secondsBeforeReset }) => {
  const [contentCopied, setContentCopied] = useState(false);
  const resetCopyStateTimeout = useRef<NodeJS.Timeout | null>(null);

  const copyTextToClipboard = (): void => {
    if (resetCopyStateTimeout.current) {
      clearTimeout(resetCopyStateTimeout.current);
    }

    navigator.clipboard.writeText(text);
    setContentCopied(true);

    resetCopyStateTimeout.current = setTimeout(
      () => {
        setContentCopied(false);
      },
      secondsBeforeReset ? secondsBeforeReset * 1000 : 30000,
    );
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      display="inline-flex"
      spacing={1}
      mx={1}
    >
      <ButtonBase>
        <ContentCopyIcon
          fontSize="inherit"
          onClick={copyTextToClipboard}
        />
      </ButtonBase>
      {contentCopied && (
        <CheckCirlceIcon
          color="success"
          sx={{ fontSize: 'inherit' }}
        ></CheckCirlceIcon>
      )}
    </Stack>
  );
};

import preval from 'preval.macro';

const interfaceLanguages: string[] = preval`
const fs = require('fs');

const pathToTranslations = './src/translations'
const translations = []
fs.readdirSync(pathToTranslations).forEach((filename) => {
  const fileSplitted =filename.split('.')
  const fileName = fileSplitted[0]
  const fileEnding = fileSplitted.pop()
  if( fileEnding === 'json'){
    translations.push(fileName)
  }
});
module.exports = translations
`;

export default interfaceLanguages;
